<template>
  <vue-snotify></vue-snotify>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Notifications',
  components: {},
  computed: {
    ...mapGetters({
      notifications: 'global/notifications'
    })
  },
  watch: {
    notifications: {
      handler: function (notifications) {
        if (notifications.length > 0) {
          let notification = notifications[notifications.length - 1]
          if (notification.type == 'success') {
            this.$snotify.success(notification.text)
          } else {
            this.$snotify.error(notification.text)
          }
          this.$store.dispatch('global/ackNotification')
        }
      },
      immediate: true,
      deep: true
    }
  }
}
</script>
