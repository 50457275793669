<template>
  <AppHeaderDropdown right no-caret>
    <template slot="header">
      <img
        :src="avatar"
        class="img-avatar"
        alt="admin@bootstrapmaster.com" />
    </template>\
    <template slot="dropdown">
      <!-- <b-dropdown-header tag="div" class="text-center"><strong>Account</strong></b-dropdown-header>
      <b-dropdown-item><i class="fa fa-bell-o" /> Updates
        <b-badge variant="info">{{ itemsCount }}</b-badge>
      </b-dropdown-item>
      <b-dropdown-item><i class="fa fa-envelope-o" /> Messages
        <b-badge variant="success">{{ itemsCount }}</b-badge>
      </b-dropdown-item>
      <b-dropdown-item><i class="fa fa-tasks" /> Tasks
        <b-badge variant="danger">{{ itemsCount }}</b-badge>
      </b-dropdown-item>
      <b-dropdown-item><i class="fa fa-comments" /> Comments
        <b-badge variant="warning">{{ itemsCount }}</b-badge>
      </b-dropdown-item> -->
      <b-dropdown-header
        tag="div"
        class="text-center">
        <strong>Settings</strong>
      </b-dropdown-header>
      <b-dropdown-item @click="profile"><i class="fa fa-user" /> Profile</b-dropdown-item>
      <b-dropdown-item @click="subscription"><i class="fa fa-refresh" /> Subscription</b-dropdown-item>
      <!-- <b-dropdown-item><i class="fa fa-wrench" /> Settings</b-dropdown-item>
      <b-dropdown-item><i class="fa fa-usd" /> Payments
        <b-badge variant="secondary">{{ itemsCount }}</b-badge>
      </b-dropdown-item>
      <b-dropdown-item><i class="fa fa-file" /> Projects
        <b-badge variant="primary">{{ itemsCount }}</b-badge>
      </b-dropdown-item>
      <b-dropdown-divider />
      <b-dropdown-item><i class="fa fa-shield" /> Lock Account</b-dropdown-item> -->
      <b-dropdown-item @click="logout"><i class="fa fa-lock" /> Logout</b-dropdown-item>
    </template>
  </AppHeaderDropdown>
</template>

<script>
import { HeaderDropdown as AppHeaderDropdown } from '@coreui/vue'
import { mapGetters } from 'vuex'
import md5 from 'md5'

export default {
  name: 'DefaultHeaderDropdownAccnt',
  components: {
    AppHeaderDropdown
  },
  data: () => {
    return {
      itemsCount: 42,
      // avatar: `https://www.gravatar.com/avatar/?d=mp`
    }
  },
  computed: {
    ...mapGetters({
      account: 'global/account'
    }),
    avatar: function () {
      let address = null
      let account = this.account || null
      if (account && account.contact && account.contact.emailAddress) {
        address = account.contact.emailAddress.address
        let hash = md5(address.trim().toLowerCase())
        return `https://www.gravatar.com/avatar/${hash}?d=mp`
      } else {
        return `https://www.gravatar.com/avatar/?d=mp`
      }
    }
  },
  methods: {
    logout (e) {
      e.preventDefault()
      this.$store.dispatch('global/logout')
    },
    profile () {
      this.$router.push({ path: '/profile' })
    },
    subscription () {
      this.$router.push({ path: '/settings/subscription/billing' })
    }
  }
}
</script>
