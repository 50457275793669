<template>
  <div class="app">
    <notifications></notifications>
    <AppHeader fixed>
      <SidebarToggler class="d-lg-none" display="md" mobile />
      <b-link class="navbar-brand" to="#">
        <img class="navbar-brand-full" v-if="logo" :src="logo" width="150" height="22" alt="Inspiring Hope and Freedom">
        <img class="navbar-brand-minimized" v-if="logoSymbol" :src="logoSymbol" width="30" height="30" alt="Inspiring Hope and Freedom">
      </b-link>
      <SidebarToggler class="d-md-down-none" display="lg" />
      <b-navbar-nav class="d-md-down-none">
        <b-nav-item class="px-3" to="/dashboard">Dashboard</b-nav-item>
        <b-nav-item class="px-3" to="/clients">Clients</b-nav-item>
        <b-nav-item v-if="$can('view', 'settings')" class="px-3" to="/settings">Settings</b-nav-item>
        <!-- <b-nav-item class="px-3 mt-3"><b-badge pill variant="danger">5 days left on trial!</b-badge></b-nav-item> -->
      </b-navbar-nav>
      <b-navbar-nav class="ml-auto">
        <b-nav-item to="/help-center">
          <i class="icon-question"></i>
        </b-nav-item>
        <!-- <b-nav-item class="d-md-down-none">
          <i class="icon-bell"></i>
          <b-badge pill variant="danger">5</b-badge>
        </b-nav-item>
        <b-nav-item class="d-md-down-none">
          <i class="icon-list"></i>
        </b-nav-item>
        <b-nav-item class="d-md-down-none">
          <i class="icon-location-pin"></i>
        </b-nav-item> -->
        <DefaultHeaderDropdownAccnt/>
      </b-navbar-nav>
      <!-- <AsideToggler class="d-none d-lg-block" /> -->
      <!--<AsideToggler class="d-lg-none" mobile />-->
    </AppHeader>
    <div class="app-body">
      <router-view name="sidebar" class="sidebar" />
      <main class="main">
        <Breadcrumb :list="list"/>
        <div class="container-fluid">
          <router-view></router-view>
        </div>
      </main>
      <AppAside fixed>
        <!--aside-->
        <DefaultAside/>
      </AppAside>
    </div>
    <TheFooter>
      <!--footer-->
      <div>
        <span class="ml-1">&copy; {{ year }} IHF LLC.</span> <b-badge variant="primary"><router-link to="/help-center/versions">v{{ appVersion }}</router-link></b-badge> <span class="ml-3"><router-link to="/privacy-policy">Privacy Policy</router-link></span> <span class="ml-3"><router-link to="/terms-and-conditions">Terms and Conditions</router-link></span>
      </div>
    </TheFooter>
  </div>
</template>

<script>
import { Header as AppHeader, SidebarToggler, Aside as AppAside, AsideToggler, Footer as TheFooter, Breadcrumb } from '@coreui/vue'
import DefaultAside from './DefaultAside'
import DefaultHeaderDropdownAccnt from './DefaultHeaderDropdownAccnt'
import Notifications from '@/components/Notifications'
import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
  name: 'full',
  components: {
    AsideToggler,
    AppHeader,
    AppAside,
    TheFooter,
    Breadcrumb,
    DefaultAside,
    DefaultHeaderDropdownAccnt,
    Notifications,
    SidebarToggler
  },
  data () {
    return {}
  },
  computed: {
    ...mapGetters({
      account: 'global/account',
      appVersion: 'global/appVersion'
    }),
    name () {
      return this.$route.name
    },
    list () {
      return this.$route.matched.filter((route) => route.name || route.meta.label )
    },
    year () {
      return moment().format('YYYY')
    },
    logo () {
      if (this.account && this.account.organizationId > 0) {
        return `//cdn.inspiringhopeandfreedom.com/organizations/${this.account.organizationId}/images/logo.png`
      } else {
        return null
      }
    },
    logoSymbol () {
      if (this.account && this.account.organizationId > 0) {
        return `//cdn.inspiringhopeandfreedom.com/organizations/${this.account.organizationId}/images/logo-symbol.png`
      } else {
        return null
      }
    }
  }
}
</script>

<style lang="scss">
.icon-question {
  font-size: 1.25rem;
}
.badge {
  a {
    color: #ffffff;
  }
}
</style>
